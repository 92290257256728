import React, { Component } from 'react'
import { Link, StaticQuery } from 'gatsby'

import Insta from '../assets/images/insta.svg'
import FB from '../assets/images/fb.svg'
import Youtube from '../assets/images/youtube.svg'
import LinkenIn from '../assets/images/linkedin.svg'
import LogoFooter from '../assets/images/logofooter.svg'

class Footer extends Component {
  render() {
    const { data } = this.props

    return (
        <>
          <footer className="footer">
            <div className="footer__inner container">
              <div className="footer__nav footer__nav--left">
                <ul>
                  <li>© {(new Date().getFullYear())} Jerry Y Property Mentor</li>
                  <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                </ul>
              </div>
              <div className="footer__nav footer__nav--right">
                <ul>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.instagram}><img src={Insta} alt="Instagram" /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.facebook}><img src={FB} alt="FaceBook" /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.youtube}><img src={Youtube} alt="YouTube"  /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.linkedin}><img src={LinkenIn} alt="LinkedIn" /></a></li>
                  <li><img src={LogoFooter} alt="Jerry Y Logo"/></li>
                </ul>
              </div>
            </div>
          </footer>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        options: wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            instagram
            facebook
            youtube
            linkedin
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
