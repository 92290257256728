import React, { Component } from 'react'
import LogoBlack from '../assets/images/LogoBlack.svg'
import Logo from '../assets/images/Logo.svg'
import { Link } from 'gatsby'

class Header extends Component {

  state = {
    show: true,
    fixed: false,
    menuActive: false,
    subMenuActive: true,
    productsActive: false,
    solutionsActive: false,
    sectorsActive: false,
    companyActive: false,
    hoverActive: false,
    pageContext: this.props.pageContext || {},
  }

  toggleMenu = () => {
    const { show } = this.state
    this.setState({ show : !show })
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
  }

  render() {
    const { menuActive } = this.state

    let props = {
      onClick: this._hideOffCanvas
    }

    // let { pathname } = this.props.location
    // let darkRoutes = ['/about', '/about/', '/contact', '/contact/', '/point-of-difference', '/point-of-difference/']
    // let dark = darkRoutes.includes(pathname)

    return (
      <>
        <header className="header">
          <div className="header__inner container">
            <div className="header__nav header__nav--left">
            <Link to="/" className='header__logo' {...props}>
              <img className="dark" src={LogoBlack} alt="Jerry" />
              <img className="light" src={Logo} alt="Jerry" />
            </Link>
            </div>
            <div className="header__nav header__nav--right">
              <ul>
                <li><Link to="/who-is-jerry">Who's Jerry</Link></li>
                <li><Link to="/what-jerry-does">What does Jerry do</Link></li>
                <li><Link to="/what-people-say">What people say</Link></li>
                <li><Link to="/news-and-events">What's happening</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
              </ul>
              <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>

        <div className={`${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
          <nav className="off-canvas__nav">
            <ul>
              <li><Link to="/who-is-jerry" {...props}>Who's Jerry</Link></li>
              <li><Link to="/what-jerry-does" {...props}>What does Jerry do</Link></li>
              <li><Link to="/what-people-say" {...props}>What people say</Link></li>
              <li><Link to="/news-and-events" {...props}>What's happening</Link></li>
              <li><Link to="/contact" {...props}>Contact us</Link></li>
            </ul>
          </nav>
        </div>
      </>
    )
  }
}



export default Header
